const COMPONENT_IDS = Object.freeze({
  hero: 'profile-hero',
  summary: 'profile-summary',
  about: 'profile-about',
  social: 'social-feed',
  statistics: 'profile-statistics',
  listings: 'profile-listings',
  testimonials: 'profile-testimonials',
  video: 'profile-video',
  enquiryForm: 'profile-enquiry-form',
  map: 'profile-map-view',
  team: 'our-team',
  recommendations: 'profile-recommendations',
  currentListings: 'current-listings',
});

export default COMPONENT_IDS;
